
let Dropdown = {
    elemSelector: '.js-ek-filter-dropdown',
    elemSelectWrapperSelector: '.ek-filter-dropdown__select',
    elemSelectSelector: '.js-ek-filter-dropdown-select',
    elemOpenClass: 'ek-filter-dropdown--open',
    elemDisabledClass: 'ek-filter-dropdown--disabled',
    $elem: null,

    init() {
        var windowKeydown = this._handleKeyDown.bind(this);
        this.$elem = $svjq(this.elemSelector);
        var outers = $svjq('.ek-news-listing-filter__item__dropdown__outer');
        outers.each((i, elem) => {
            elem.addEventListener('keydown', windowKeydown, false)
        });

        this.$elemSelectWrapper = $svjq(this.elemSelectWrapperSelector);

        if(this.$elem.length === 0) {
          return;
        }

        this.$elem.each((i, elem) => {
            let $elem = $svjq(elem);
            //elem.addEventListener('keydown', windowKeydown, false);
            this._createList($elem);
            this._disableSelect($elem);
        });

        this.$elem.on('keydown', (e) => {
            if(!$svjq(e.target.parentNode).hasClass(this.elemOpenClass) && (e.keyCode === 38 || e.keyCode === 40)){
                if (!$svjq(e.target).hasClass('ek-filter-dropdown__list__option')) {
                    this._listClickHandler($svjq(e.currentTarget));
                }
            }
        });
        this.$elem.on('mousedown', (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.className.indexOf('blocker') <= 0) {
                var viewportOffset = e.target.getBoundingClientRect();
                var elemX = viewportOffset.left;
                var elemWidth = parseInt(getComputedStyle(e.target).width.replace('px', ''));
                var elemRightX = elemX + elemWidth;
                var clickX = e.clientX;

                /* We will not
                 * do anything if the click was '64' pixels from the right side
                 * towards the left side.
                 */
                if (clickX >= (elemRightX - 64)) { return; }
            }
            if (!$svjq(e.target).hasClass('ek-filter-dropdown__list__option')) {
                this._listClickHandler($svjq(e.currentTarget));
            }
            
            
        });

        this.$elemSelectWrapper.on('keydown', (e)=>{
            if(e.keyCode === 32){
                this._listClickHandler($svjq(e.currentTarget.parentNode));
            }
            
        }); 

        this.$elemSelectWrapper.on('focusin', (e) => {

                if (!$svjq(e.target.parentNode).hasClass('ek-filter-dropdown__list__option')) {
                    //this._listClickHandler($svjq(e.currentTarget.parentNode));
                }
                $svjq(e.target).addClass('ek-filter-dropdown--focused');

                e.stopImmediatePropagation();
                e.stopPropagation();
        });

        this.$elemSelectWrapper.on('focusout', (e) => {


                if ($svjq(e.target.parentNode).hasClass('ek-filter-dropdown--open') && !($svjq(e.target).hasClass('ek-filter-dropdown--focused'))) {
                    //this._listClickHandler($svjq(e.currentTarget.parentNode));
                }
                if ($svjq(e.target).hasClass('ek-filter-dropdown--focused')) {
                    $svjq(e.target).removeClass('ek-filter-dropdown--focused');
                }
                
                e.stopImmediatePropagation();
                e.stopPropagation();
            });

    },
    _handleKeyDown(event) {
        var $e = $svjq(this.$elem);
      if(this.$elem.hasClass(this.elemOpenClass) && (event.keyCode===40||event.keyCode===38)) {
            event.preventDefault();
            event.stopPropagation();
            if($e.find('li.js--focus').length === 0) {

              $e.find('li').first().addClass('js--focus')
                .find('button').focus();
                

            } else {
                if($e.find('li.js--focus').length > 1){
                    $e.find('li').first().addClass('js--focus')
                    .find('button').focus();
                }
                var selected = $e.find('.js--focus');
               
                if(event.keyCode===38) { // keyUp                    
                    $e.find('li').removeClass('js--focus');
                    // if there is no element before the selected one, we select the last one
                    if (selected.prev().length == 0) {
                        selected.siblings().last().addClass('js--focus')
                        .find('button').focus();
                    } else { // otherwise we just select the next one
                        selected.prev().addClass('js--focus')
                        .find('button').focus();
                    }

                } else if(event.keyCode===40) { // keyDown
                    
                    $e.find('li').removeClass('js--focus');

                    // if there is no element after the selected one, we select the first one
                    if (selected.next().length == 0) {
                        selected.siblings().first().addClass('js--focus')
                        .find('button').focus();
                    } else { // otherwise we just select the prev one
                        selected.next().addClass('js--focus')
                        .find('button').focus();
                    }

                }
            }
            
        
      }else if(event.keyCode===9){
        if(!!window.event.shiftKey && this.$elem.hasClass(this.elemOpenClass)){
            var prevElement = this.$elem.prev();
            $e.removeClass(this.elemOpenClass);
            if(prevElement.length > 0){
                prevElement.find('.js-ek-filter-dropdown-select').focus();

            }else{
                $e.focus();
            }
        }else{
            $e.removeClass(this.elemOpenClass);
        } 
        
      }else if(event.keyCode === 32 && $svjq(event.target.parentNode).hasClass('js-ek-filter-dropdown')){
          event.preventDefault();
          event.stopPropagation();
      }
    },
    _listClickHandler($elem) {
 
        if (this._isDisabled($elem)) return;
        var isOpen = $elem.hasClass(this.elemOpenClass);
        this.$elem = $elem;
        if(isOpen){ // we will close
          $elem.find('li').removeClass('js--focus');          
          
        }
        $elem.siblings().removeClass('ek-filter-dropdown--open');

        $elem.toggleClass(this.elemOpenClass, !isOpen);

    },
    _optionClickHandler($option) {

        let $comp = $option.closest(this.elemSelector);
        let $select = $svjq('select', $comp);
        let self = this;

        $comp.removeClass(self.elemOpenClass);

        //$option.toggleClass('ek-filter-dropdown__list__option--selected');

        $comp.find('.ek-filter-dropdown__list').find('li').removeClass('js--focus');


        $select.trigger('change');
    },
    _disableSelect($elem) {
        this._addBlocker($elem);
    },
    _createList($elem) {
        var $this = this;
        

        var current = false;
        

        let $selectElem = $svjq(this.elemSelectSelector, $elem);
        if(current != false){
            $selectElem.val(current);
        }

        if($elem.find('.ek-filter-dropdown__list').length > 0){
            $svjq('button', $elem).on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                $this._optionClickHandler($svjq(e.currentTarget));
            });
        } 
    },
    _addBlocker($elem) {
        if($elem.find('.ek-filter-dropdown__select__blocker').length == 0){
            $svjq('.ek-filter-dropdown__select', $elem).append('<div class="ek-filter-dropdown__select__blocker" />');
        }
        
    },
    _isDisabled($elem) {
        return $elem.hasClass(this.elemDisabledClass);
    }
};

export default Dropdown;