import './app.scss';

import Accordion from 'accordion-js';
import Dropdown from './app/components/c-filter-dropdown/c-filter-dropdown.js';

$svjq(document).ready(function () {
    window.dropDown = Dropdown;
    Dropdown.init();
    $svjq('.ek-accordion').each(function (index, element) {
        $svjq(element).addClass('accordion-' + index);
        new Accordion('.accordion-' + index,
            {
                elementClass: 'ek-accordion__faq',
                questionClass: 'ek-accordion__faq-heading',
                answerClass: 'ek-accordion__faq-content',
                closeOthers: false,
            });

    });

    

    // Add body class when using keyboard
    $svjq('body').on('keydown', function (e) {
        let $svjqcode = e.keyCode || e.which;

        if ($svjqcode == '9') {
            $svjq('body').addClass('using-keyboard');
        }
    });

    $svjq('body').on('mousedown', function () {
        $svjq('body').removeClass('using-keyboard');
    });

    // Mobile navigation
    var mobileNav = $svjq('.ek-mobile-nav');
    var mobileNavToggle = $svjq('.ek-header__toggle-wrapper');
    var mobileNavIcon = $svjq('.ek-header__toggle-menu');
    var header = $svjq('.ek-header');
    mobileNav.attr('aria-hidden', 'true');

    mobileNavToggle.on('click', function () {
        header.toggleClass('ek-header-toggle');
        mobileNavIcon.toggleClass('ek-header__toggle-menu--close');
        mobileNav.toggleClass('ek-mobile-nav--expanded');
        //$svjq('body').toggleClass('no-scroll');

        if (mobileNavIcon.attr('aria-expanded') === 'false') {
            mobileNavIcon.attr('aria-expanded', 'true');
        } else {
            mobileNavIcon.attr('aria-expanded', 'false');
        }

        if (mobileNav.attr('aria-hidden') === 'true') {
            mobileNav.attr('aria-hidden', 'false');
        } else {
            mobileNav.attr('aria-hidden', 'true');
        }
    });


    // mobile navigation
    var mobileNavToggle = $svjq('.ek-mobile-nav__toggle');

    if (mobileNavToggle) {
        mobileNavToggle.on('click', function () {
            $svjq(this).toggleClass('ek-mobile-nav__toggle--close');
            $svjq(this).prev().toggleClass('ek-mobile-nav__menu-item--expanded');
            $svjq(this).next().toggleClass('ek-mobile-nav__menu--visible');

            if ($svjq(this).attr('aria-expanded') === 'false') {
                $svjq(this).attr('aria-expanded', 'true');
            } else {
                $svjq(this).attr('aria-expanded', 'false');
            }

            if ($svjq(this).next().attr('aria-hidden') === 'true') {
                $svjq(this).next().attr('aria-hidden', 'false');
            } else {
                $svjq(this).next().attr('aria-hidden', 'true');
            }
        });

        var mobileNav = $svjq('.ek-mobile-nav');
        mobileNav.each(function (index, element) {
            var openedElements = $svjq(element).find('.ek-mobile-nav__menu--visible').first().find('.ek-mobile-nav__menu--visible');

            if (openedElements.length < 1) {
                openedElements = $svjq(element).find('.ek-mobile-nav__menu-item--current');
            }

            openedElements.each(function (index, element) {
                var toggleElements = $svjq(element).parents().siblings('.ek-mobile-nav__toggle');
                toggleElements.each(function (index, element) {
                    var navToggle = $svjq(element);
                    var navMenu = navToggle.siblings('.ek-mobile-nav__menu').first();
                    var navLink = navToggle.siblings('.ek-mobile-nav__link').first();

                    navMenu.addClass('ek-mobile-nav__menu--visible');
                    navMenu.attr('aria-hidden', 'false');

                    navLink.addClass('ek-mobile-nav__menu-item--expanded');


                    navToggle.addClass('ek-mobile-nav__toggle--close');
                    navToggle.attr('aria-expanded', 'true')
                    navToggle.attr('aria-haspopup', 'true');
                });

            });
        });
    }

    // Side navigation
    var sideNavToggle = $svjq('.ek-side-nav__toggle');

    if (sideNavToggle) {
        sideNavToggle.on('click', function () {
            $svjq(this).toggleClass('ek-side-nav__toggle--close');
            $svjq(this).prev().toggleClass('ek-side-nav__menu-item--expanded');
            $svjq(this).next().toggleClass('ek-side-nav__menu--visible');

            if ($svjq(this).attr('aria-expanded') === 'false') {
                $svjq(this).attr('aria-expanded', 'true');
            } else {
                $svjq(this).attr('aria-expanded', 'false');
            }

            if ($svjq(this).next().attr('aria-hidden') === 'true') {
                $svjq(this).next().attr('aria-hidden', 'false');
            } else {
                $svjq(this).next().attr('aria-hidden', 'true');
            }
        });

        var sideNav = $svjq('.ek-side-nav');
        sideNav.each(function (index, element) {
            var openedElements = $svjq(element).find('.ek-side-nav__menu--visible');
            if (openedElements.length < 1) {
                openedElements = $svjq(element).find('.ek-side-nav__menu-item--current');
            }
            openedElements.each(function (index, element) {
                var toggleElements = $svjq(element).parents().siblings('.ek-side-nav__toggle');

                toggleElements.each(function (index, element) {
                    var navToggle = $svjq(element);
                    var navMenu = navToggle.siblings('.ek-side-nav__menu').first();
                    var navLink = navToggle.siblings('.ek-side-nav__link').first();

                    navMenu.addClass('ek-side-nav__menu--visible');
                    navMenu.attr('aria-hidden', 'false');

                    navLink.addClass('ek-side-nav__menu-item--expanded');


                    navToggle.addClass('ek-side-nav__toggle--close');
                    navToggle.attr('aria-expanded', 'true')
                    navToggle.attr('aria-haspopup', 'true');
                });

            });
        });
    }

    // Scroll down button
    var scrollButton = $svjq('.ek-scroll-down__button');
    var main = $svjq('.ek-main');

    if (scrollButton) {
        scrollButton.on('click', function () {
            $svjq('html, body').animate({ scrollTop: main.offset().top }, 500);
        });
    }

    // Hero search bar autocomplete
    var heroSearchBar = $svjq('.ek-hero-search-bar');

    if (heroSearchBar) {
        $svjq('body').addClass('has-hero-search-bar')
    }

    // Feedback form
    var feedback = $svjq('.ek-feedback');

    if (feedback) {
        var feedbackButton = $svjq('.ek-feedback__button');
        var feedbackPositive = $svjq('.ek-feedback__button--positive');
        var feedbackNegative = $svjq('.ek-feedback__button--negative');
        var feedbackForm = $svjq('.ek-feedback__form');
        var feedbackMessage = $svjq('.ek-feedback__message');

        feedbackPositive.on('click', function (e) {
            e.preventDefault();
            feedbackMessage.addClass('ek-feedback__message--visible');
            feedbackButton.attr('disabled', 'true');

            if (feedbackMessage.attr('aria-hidden') === 'true') {
                feedbackMessage.attr('aria-hidden', false)
                return feedbackPositive.attr('aria-expanded', true)
            } else {
                feedbackMessage.attr('aria-hidden', true)
                return feedbackPositive.attr('aria-expanded', false)
            }
        });

        feedbackNegative.on('click', function (e) {
            e.preventDefault();
            feedbackForm.addClass('ek-feedback__form--visible');
            feedbackButton.attr('disabled', 'true');

            if (feedbackForm.attr('aria-hidden') === 'true') {
                feedbackForm.attr('aria-hidden', false)
                return feedbackNegative.attr('aria-expanded', true)
            } else {
                feedbackForm.attr('aria-hidden', true)
                return feedbackNegative.attr('aria-expanded', false)
            }
        });

        if (window.location.href.indexOf("state=create") > -1) {
            feedbackNegative.trigger("click");
        }
    }


    $svjq('.ek-important-message__toggle,.ek-important-message-closed__toggle').on('click', function () {
        var vma = $svjq('.ek-important-message');
        if (vma.length > 0) {
            var showDiv = vma.find('.ek-important-message__text-show');
            if (showDiv.length > 0) {
                if (showDiv.is(":visible")) {
                    if (getCookie('acceptcookie')) {
                        SetVmaState('closed');
                    }
                } else {
                    if (getCookie('acceptcookie')) {
                        SetVmaState('opened');
                    }
                }
                vma.toggleClass('ek-important-message-closed');
                showDiv.slideToggle();
            }
        }
    });

    function SetVmaState(state) {
        var d = new Date();
        var exdays = 1;
        d = new Date(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        document.cookie = "vmastate=" + state + ";path=/;expires=" + d.toGMTString();
        console.log(getCookie('vmastate'));
    }


    $svjq('.ek-cookie-prompt__close').on('click', function () {
        var d = new Date();
        d = new Date(d.getTime() + 1000 * 60 * 60 * 24 * 365);
        document.cookie = "acceptcookie=true;path=/;expires=" + d.toGMTString();
        location.reload();
    });

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                var value = c.substring(name.length, c.length);

                if (value.toLowerCase() == 'true') {
                    return true;
                } else if (value.toLowerCase() == 'false') {
                    return false;
                }
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    var haveCookie = getCookie('acceptcookie');
    var cookiePrompt = document.getElementById("cookiePrompt");
    if (cookiePrompt) {
        var vma = $svjq('.ek-important-message');
        var vmaShowDiv = $svjq('.ek-important-message__text-show');
        if (!haveCookie) {
            $svjq("#cookiePrompt").show();
            vmaShowDiv.show();
        }
        else {
            var vmastate = getCookie('vmastate');
            var closed = $svjq('.ek-important-message-closed__outer');
            var outer = $svjq('.ek-important-message__outer');
            if (vmastate && vmastate.length > 0) {
                if (vmastate == 'opened' || vmastate == '' || !vmastate) {
                    vmaShowDiv.show();
                    vma.removeClass('ek-important-message-closed');
                } else {
                    vmaShowDiv.hide();
                    vma.addClass('ek-important-message-closed');
                }
            } else {
                vmaShowDiv.show();
                vma.removeClass('ek-important-message-closed');
            }
            $svjq("#cookiePrompt").hide();
        }
    }

    var header = $svjq('.ek-header');

    if(header){
        var sticky = header[0].offsetTop;

        if (window.pageYOffset > sticky) {
            header.addClass("ek-header-sticky");
        } else {
            header.removeClass("ek-header-sticky");
        }
        $svjq(window).on('scroll',function(evt){
            if (window.pageYOffset > sticky) {

                header.addClass("ek-header-sticky");
              } else {
                header.removeClass("ek-header-sticky");
              }
        });
    }
});
